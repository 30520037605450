<template>
  <b-overlay spinner-variant="success" opacity="1" :show="loading" id="main">
    <router-view />
  </b-overlay>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "index",

  computed: {
    ...mapGetters({
      loading: 'getLoading'
    })
  },
}
</script>

<style scoped>

</style>